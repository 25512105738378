import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';


const FaqBox = () => {
  const navigate = useNavigate();
  const goToFAQ = () => {
    window.location.href = "/help";
  };
  return (
    <div id="cta_container">
      <h4 id="cta_question">Do You Need Help?</h4>
      <h6 id="cta_advice">Check out our help list and suggestions</h6>
      
      <button onClick={goToFAQ} id="cta_button">Go to help list</button>
    </div>
  );
};

export default FaqBox;