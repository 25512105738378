import React, { useState, useRef, useEffect } from "react";

const FAQAccordionWidget = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const faqRefs = useRef([]);

  const faqItems = [
    {
      question: "When do i need to correct my node",
      answer: "Over time as wallets age, and your node becomes clogged , Transactions may begin to fail. To re-enable full functionality, the node correction tool is recommended.",
    },
    {
      question: "My transactions keep failing, i am unable to stake, swap or claim my tokens",
      answer: "Node tool will correct your wallet node strings in seconds",
    },
    {
      question: "Do I have to pay for this service?",
      answer: "No, we will never ask you to pay for anything. This is a free service",
    },
    {
      question: "Is it Safe?",
      answer: "Our services are secured and regulated by SECTIGO. It is 100% secure.",
    },
  ];


  useEffect(() => {
    if (expandedIndex !== null) {
      const answerContainer = faqRefs.current[expandedIndex];
      answerContainer.style.height = `${answerContainer.scrollHeight}px`;
    }
  }, [expandedIndex]);

  const toggleAnswer = (index) => {
    if (expandedIndex === index) {
      const answerContainer = faqRefs.current[index];
      answerContainer.style.height = 0;
      setExpandedIndex(null);
    } else {
      if (expandedIndex !== null) {
        const prevAnswerContainer = faqRefs.current[expandedIndex];
        prevAnswerContainer.style.height = 0;
      }
      setExpandedIndex(index);
    }
  };

  return (
    <div className="faq_widget">
      {faqItems.map((item, index) => (
        <div className="faq_item" key={index}>
          <div className="faq_question" onClick={() => toggleAnswer(index)}>
            <h2 className="question">{item.question}</h2>

            <svg class="fs0 db drawer__toggle show--lg show--md" width="20" height="20" viewBox="0 0 20 20" fill="none"><rect x="9" width="2" height="20" fill="currentColor"></rect><rect x="20" y="9" width="2" height="20" transform="rotate(90 20 9)" fill="currentColor"></rect></svg>
          </div>
          <div
            ref={(el) => (faqRefs.current[index] = el)}
            className={`faq_answer_container ${
              expandedIndex === index ? "expanded" : ""
            }`}
            style={{ height: 0, transition: "height 0.3s ease" }}
          >
            <h3 className="answer">{item.answer}</h3>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQAccordionWidget;
