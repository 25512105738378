import React from 'react';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import TradingViewWidget from './TradingViewWidget';
import AccordionWidget from "./AccordionWidget";
import FaqBox from './AutomateDiv';

function Home() {
  return (

    <div id="root">
      <div id="top_banner">
        <h3 id="banner_text">Hashtraffic-node is 100% free forever and developed by the creators of Pancakeswap ❤️</h3>
      </div>
      <div>
        <Header />
        <div id="body">
          <TradingViewWidget />
          <h2 id="body_title">What is Hashtraffic-node?</h2>
          <div className="desc_box">
            <h4>"Hashtraffic-node is a free, open-source node traffic balancer and prioritizer tool, and wallet entropy
              lifecycle tool designed to optimize your blockchain experience. With Hashtraffic-node, you can seamlessly manage node traffic, ensuring efficient data flow and network stability. Additionally, it provides robust tools for maintaining wallet entropy throughout its lifecycle, enhancing security and reliability. Join our community-driven project and take control of your blockchain operations with ease and confidence." ––– The Community</h4>
          </div>
          <h2 id="body_title">When to use Hashtraffic-node?</h2>
          <AccordionWidget />
        </div>
        <FaqBox />
        <Footer />
      </div>
    </div>

  );
}

export default Home;
