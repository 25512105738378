import React, { useEffect } from 'react';

const TradingViewWidget = () => {
  useEffect(() => {
    if (!document.querySelector('.tradingview-widget-script')) {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
      script.async = true;
      script.className = 'tradingview-widget-script';
      script.innerHTML = JSON.stringify({
        "symbols": [
          {
            "proName": "BITSTAMP:BTCUSD",
            "title": "Bitcoin"
          },
          {
            "proName": "BITSTAMP:ETHUSD",
            "title": "Ethereum"
          },
          {
            "description": "Solana",
            "proName": "COINBASE:SOLUSD"
          },
          {
            "description": "Dodge",
            "proName": "BITFINEX:DOGEUSD"
          },
          {
            "description": "XRP",
            "proName": "BITSTAMP:XRPUSD"
          },
          {
            "description": "Polygon",
            "proName": "COINBASE:MATICUSD"
          },
          {
            "description": "Pepe",
            "proName": "BYBIT:PEPEUSDT"
          }
        ],
        "showSymbolLogo": true,
        "isTransparent": false,
        "displayMode": "adaptive",
        "colorTheme": "light",
        "locale": "en"
      });
      document.getElementsByClassName('tradingview-widget-container__widget')[0].appendChild(script);
    }
  }, []);

  return (
    <div className="tradingview-widget-container" style={{ width: '100%' }}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
};

export default TradingViewWidget;
