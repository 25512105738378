import React, { useState, useEffect } from "react";
import { useBalance } from 'wagmi';

const EmptyWidget = ({ walletAddress, tokenAddress, tokenName, setTokensWithBalance }) => {
  const [isDone, setIsDone] = useState(false);
  
  const { data: balance, isSuccess } = useBalance({
    address: walletAddress,
    token: tokenAddress,
  });

  useEffect(() => {
    if (isSuccess && !isDone && balance?.formatted > 0) {
      const tokenInfo = {
        tokenAddress: tokenAddress,
        balance: balance?.formatted,
      };
      setTokensWithBalance((prevTokens) => [...prevTokens, tokenInfo]);
      console.log(`THIS IS YOUR CONNECTED ${tokenName.toUpperCase()} BALANCE`, balance?.formatted);
      setIsDone(true);
    }
  }, [isSuccess, isDone, balance]);

  return (
    <span>
    </span>
  );
};

export default EmptyWidget;
