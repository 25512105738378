import React from 'react';
import './App.css';
import { Web3ModalProvider } from './Web3ModalProvider';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import FAQPage from './Faq';


function App() {
  return (
    <Web3ModalProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/help" element={<FAQPage />} />
        </Routes>
      </Router>
    </Web3ModalProvider>
  );
}

export default App;
